import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  line-height: 1.5em;
  margin: auto;
  max-width: 800px;
  width: auto;
  padding: 1em;
  code {
    font-size: 85%;
  }
`;

const Nav = styled.ul`
  float: right;
`;

const NavLink = props => (
  <li
    css={css`
      display: inline-block;
      margin-right: 1rem;
    `}>
    <Link to={props.to}>{props.children}</Link>
  </li>
);

export default ({ children }) => (
  <Wrapper>
    <Nav>
      <NavLink to="/">Home</NavLink>
      <NavLink to="/blog/">Blog</NavLink>
    </Nav>
    <main
      css={css`
        clear: both;
      `}>
      {children}
    </main>
  </Wrapper>
);
